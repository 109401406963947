import { AccountDocument } from '@admin-api/index';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

export interface IRequestInformationDialogReturnData {
  type: AccountDocument.TypeEnum;
  subtype: AccountDocument.SubtypeEnum;
  requestNotes?: string;
}

@Component({
  selector: 'app-request-information-dialog',
  templateUrl: './request-information-dialog.component.html',
  styleUrls: ['./request-information-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RequestInformationDialogComponent {
  @ViewChild('stepper') stepper: MatStepper;
  public writtenSubtypes = [
    AccountDocument.SubtypeEnum.BUSINESS_MODEL,
    AccountDocument.SubtypeEnum.PRICING_MODEL,
    AccountDocument.SubtypeEnum.WEBSITE,
    AccountDocument.SubtypeEnum.SHOPPING_CART,
    AccountDocument.SubtypeEnum.OTHER,
  ];
  public documentSubtypes = [
    AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK,
    AccountDocument.SubtypeEnum.EIN_LETTER,
    AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP,
    AccountDocument.SubtypeEnum.BUSINESS_LICENSE,
    AccountDocument.SubtypeEnum.UTILITY_BILL,
    AccountDocument.SubtypeEnum.PROCESSING_STATEMENT,
    AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT,
    AccountDocument.SubtypeEnum.BANK_STATEMENT,
    AccountDocument.SubtypeEnum._501C3,
    AccountDocument.SubtypeEnum.TAX_RETURN,
    AccountDocument.SubtypeEnum.INVOICE,
    AccountDocument.SubtypeEnum.DL_PASSPORT,
    AccountDocument.SubtypeEnum.DL_BACK,
    AccountDocument.SubtypeEnum.OTHER,
  ];

  public writtenForm: UntypedFormGroup;
  public documentForm: UntypedFormGroup;
  public writtenRequestForm: UntypedFormGroup;
  public documentRequestForm: UntypedFormGroup;

  public checkedWrittenSubtypes: AccountDocument.SubtypeEnum[] = [];
  public checkedDocumentSubtypes: AccountDocument.SubtypeEnum[] = [];

  constructor(
    public matDialogRef: MatDialogRef<RequestInformationDialogComponent>,
    private fb: FormBuilder,
  ) {
    this.writtenRequestForm = this.fb.group({});
    this.documentRequestForm = this.fb.group({});

    this.writtenForm = this.fb.group({
      [AccountDocument.SubtypeEnum.BUSINESS_MODEL.toString()]: [false],
      [AccountDocument.SubtypeEnum.PRICING_MODEL.toString()]: [false],
      [AccountDocument.SubtypeEnum.WEBSITE.toString()]: [false],
      [AccountDocument.SubtypeEnum.SHOPPING_CART.toString()]: [false],
      [AccountDocument.SubtypeEnum.OTHER.toString()]: [false],
    });

    this.documentForm = this.fb.group({
      [AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK.toString()]: [
        false,
      ],
      [AccountDocument.SubtypeEnum.EIN_LETTER.toString()]: [false],
      [AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP.toString()]: [false],
      [AccountDocument.SubtypeEnum.BUSINESS_LICENSE.toString()]: [false],
      [AccountDocument.SubtypeEnum.UTILITY_BILL.toString()]: [false],
      [AccountDocument.SubtypeEnum.PROCESSING_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum.BANK_STATEMENT.toString()]: [false],
      [AccountDocument.SubtypeEnum._501C3.toString()]: [false],
      [AccountDocument.SubtypeEnum.TAX_RETURN.toString()]: [false],
      [AccountDocument.SubtypeEnum.INVOICE.toString()]: [false],
      [AccountDocument.SubtypeEnum.DL_PASSPORT.toString()]: [false],
      [AccountDocument.SubtypeEnum.DL_BACK.toString()]: [false],
      [AccountDocument.SubtypeEnum.OTHER.toString()]: [false],
    });
  }

  public cancelClicked() {
    this.matDialogRef.close(null);
  }

  public backPage() {
    this.checkedWrittenSubtypes = [];
    this.checkedDocumentSubtypes = [];
    this.writtenRequestForm = this.fb.group({});
    this.documentRequestForm = this.fb.group({});
    this.stepper.previous();
  }

  public nextPage() {
    for (const subtype of this.writtenSubtypes) {
      const control = this.writtenForm.get(subtype.toString());
      if (control && control.value) {
        this.checkedWrittenSubtypes.push(subtype);
      }
    }
    for (const subtype of this.documentSubtypes) {
      const control = this.documentForm.get(subtype.toString());
      if (control && control.value) {
        this.checkedDocumentSubtypes.push(subtype);
      }
    }

    for (const type of this.checkedWrittenSubtypes) {
      if (type === AccountDocument.SubtypeEnum.OTHER) {
        this.writtenRequestForm.addControl(
          type.toString(),
          this.fb.control('', [Validators.required]),
        );
      } else {
        this.writtenRequestForm.addControl(
          type.toString(),
          this.fb.control(''),
        );
      }
    }
    for (const type of this.checkedDocumentSubtypes) {
      if (type === AccountDocument.SubtypeEnum.OTHER) {
        this.documentRequestForm.addControl(
          type.toString(),
          this.fb.control('', [Validators.required]),
        );
      } else {
        this.documentRequestForm.addControl(
          type.toString(),
          this.fb.control(''),
        );
      }
    }

    this.stepper.next();
  }

  public submitClicked() {
    if (this.writtenRequestForm.invalid || this.documentRequestForm.invalid) {
      return;
    }

    const returnData: IRequestInformationDialogReturnData[] = [];
    for (const [subtype, value] of Object.entries(
      this.writtenRequestForm.value,
    )) {
      returnData.push({
        type: AccountDocument.TypeEnum.WRITTEN,
        subtype: subtype as AccountDocument.SubtypeEnum,
        requestNotes: (value as string).trim(),
      });
    }
    for (const [subtype, value] of Object.entries(
      this.documentRequestForm.value,
    )) {
      returnData.push({
        type: AccountDocument.TypeEnum.FILE,
        subtype: subtype as AccountDocument.SubtypeEnum,
        requestNotes: (value as string).trim(),
      });
    }

    this.matDialogRef.close(returnData.length > 0 ? returnData : null);
  }

  public mapSubtypeToTitle(subtype: AccountDocument.SubtypeEnum): string {
    switch (subtype) {
      //file
      case AccountDocument.SubtypeEnum.BANK_LETTER_VOIDED_CHECK:
        return 'Bank Letter or Voided Check';
      case AccountDocument.SubtypeEnum.EIN_LETTER:
        return 'EIN Letter';
      case AccountDocument.SubtypeEnum.ARTICLES_OF_INCORP:
        return 'Articles of Incorporation';
      case AccountDocument.SubtypeEnum.BUSINESS_LICENSE:
        return 'Business License';
      case AccountDocument.SubtypeEnum.UTILITY_BILL:
        return 'Utility Bill';
      case AccountDocument.SubtypeEnum.PROCESSING_STATEMENT:
        return 'Processing Statement (past 3 - 6 months)';
      case AccountDocument.SubtypeEnum.FINANCIAL_STATEMENT:
        return 'Financial Statement (past 1 - 2 years, include P&L Statement and Balance Sheet)';
      case AccountDocument.SubtypeEnum.BANK_STATEMENT:
        return 'Bank Statements (past 3 - 6 months)';
      case AccountDocument.SubtypeEnum._501C3:
        return '501c3';
      case AccountDocument.SubtypeEnum.TAX_RETURN:
        return 'Tax Return';
      case AccountDocument.SubtypeEnum.INVOICE:
        return 'Invoice';
      case AccountDocument.SubtypeEnum.DL_PASSPORT:
        return 'Driver’s License/Passport';
      case AccountDocument.SubtypeEnum.DL_BACK:
        return 'Driver’s License - Back side';
      //written
      case AccountDocument.SubtypeEnum.BUSINESS_MODEL:
        return 'Business model details';
      case AccountDocument.SubtypeEnum.PRICING_MODEL:
        return 'Pricing model details';
      case AccountDocument.SubtypeEnum.WEBSITE:
        return 'Website';
      case AccountDocument.SubtypeEnum.SHOPPING_CART:
        return 'No shopping cart';
      case AccountDocument.SubtypeEnum.OTHER:
        return 'Other (requires additional notes)';
      default:
        return '';
    }
  }

  public defaultRequestNoteValue(subtype: AccountDocument.SubtypeEnum): void {
    switch (subtype) {
      case AccountDocument.SubtypeEnum.TAX_RETURN:
        const priorYear = new Date().getFullYear() - 1;
        this.documentRequestForm.get(subtype).setValue(priorYear.toString());
        break;
      default:
        return;
    }
  }
}
