/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AchDebitDetails } from './achDebitDetails';
import { TerminalReader } from './terminalReader';
import { PaymentMethodBillingDetails } from './paymentMethodBillingDetails';
import { CardDetails } from './cardDetails';
import { EftDebitDetails } from './eftDebitDetails';


export interface PaymentMethod { 
    /**
     * If this is a `ach_debit` PaymentMethod, this hash contains details about the ACH Debit bank account.
     */
    ach_debit?: AchDebitDetails;
    /**
     * Billing information associated with the PaymentMethod that may be used or required by particular types of payment methods. (e.g. it is required for `ach_debit` and Apple Pay)
     */
    billing_details?: PaymentMethodBillingDetails;
    /**
     * If this is a `card` PaymentMethod, this hash contains the user\'s card details.
     */
    card?: CardDetails;
    card_present?: CardDetails;
    /**
     * Indicates whether or not the payment method can be used to make a charge. When a payment method is created via Tilled.js, a payment method is only capable of being used once (i.e. single-use) unless it is attached to a customer, in which case it becomes reusable.
     */
    chargeable: boolean;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The ID of the Customer to which this PaymentMethod is saved.
     */
    customer_id?: string;
    /**
     * If this is a `eft_debit` PaymentMethod, this hash contains details about the EFT Debit bank account.
     */
    eft_debit?: EftDebitDetails;
    /**
     * The time at which this PaymentMethod expires and is no longer available. PaymentMethods are created using a token generated by Tilled.js representing the details of a payment method (e.g. card or bank details). This token is a single-use token with an expiration date. Use `/payment-methods/{id}/attach` to make this PaymentMethod reusable.
     */
    expires_at?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    metadata?: { [key: string]: string; };
    /**
     * A customer provided name for the PaymentMethod.
     */
    nick_name?: string;
    /**
     * If the payment method type is \"card_present\" then we return the terminal_reader object.
     */
    terminal_reader?: TerminalReader;
    /**
     * The type of the PaymentMethod. An additional hash is included on the PaymentMethod with a name matching this value. It contains additional information specific to the PaymentMethod type.
     */
    type: PaymentMethod.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace PaymentMethod {
    export type TypeEnum = 'card' | 'ach_debit' | 'eft_debit' | 'card_present';
    export const TypeEnum = {
        CARD: 'card' as TypeEnum,
        ACH_DEBIT: 'ach_debit' as TypeEnum,
        EFT_DEBIT: 'eft_debit' as TypeEnum,
        CARD_PRESENT: 'card_present' as TypeEnum
    };
}


