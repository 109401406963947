/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminCollectionIntent { 
    account_id: string;
    account_name?: string;
    amount: number;
    attempt_count: number;
    currency: AdminCollectionIntent.CurrencyEnum;
    id: string;
    last_failed_at: string;
    last_failure_message: string;
    min_status_change_at: string;
    processed_at: string;
    provider_account_id: string;
    status: AdminCollectionIntent.StatusEnum;
}
export namespace AdminCollectionIntent {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type StatusEnum = 'pending' | 'ready' | 'failed' | 'processed';
    export const StatusEnum = {
        PENDING: 'pending' as StatusEnum,
        READY: 'ready' as StatusEnum,
        FAILED: 'failed' as StatusEnum,
        PROCESSED: 'processed' as StatusEnum
    };
}


