import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Directive({
  selector: '[app-drag-and-drop]',
})
export class DragAndDropDirective {
  @HostBinding('class.turnOffPointerEvents') turnOffPointerEvents: boolean;
  @HostBinding('class.dropAreaOver') dropAreaOver: boolean;
  @Input() allowMultipleFiles: boolean = true;
  @Output() fileDropped = new EventEmitter<any>();
  @Output() showOverlay = new EventEmitter<any>();

  private enterCounter: number = 0;

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.enterCounter++;
    this.dropAreaOver = true;
    this.turnOffPointerEvents = true;
    this.showOverlay.emit(true);
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    // this ensures a rogue dragleave does not trigger this, it can be finicky
    // a higher number is safer, but not as responsive
    if (this.enterCounter > 2) {
      this.enterCounter = 0;
      this.dropAreaOver = false;
      this.turnOffPointerEvents = false;
      this.showOverlay.emit(false);
    }
  }

  // Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dropAreaOver = false;
    this.turnOffPointerEvents = false;
    this.showOverlay.emit(false);

    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      if (this.allowMultipleFiles) {
        this.fileDropped.emit(files);
      } else {
        this.fileDropped.emit(files[0]);
      }
    }
  }
}
