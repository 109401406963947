import { Injectable } from '@angular/core';
import { EMPTY, expand, Observable, Subject } from 'rxjs';
import {
  AdminCreateFileRequestParams,
  AdminDeleteFileRequestParams,
  AdminService,
  ListFilesRequestParams,
  ModelFile,
} from './../../../../../projects/tilled-admin-api/src';

@Injectable({
  providedIn: 'root',
})
export class FilesAppService {
  constructor(private _adminService: AdminService) {}

  private fileListBuilder: ModelFile[] = [];
  private _filesAll$ = new Subject<ModelFile[]>();
  public filesAll$: Observable<ModelFile[]> = this._filesAll$.asObservable();

  public createFile(
    params: AdminCreateFileRequestParams,
  ): Observable<ModelFile> {
    return this._adminService.adminCreateFile(params);
  }

  public deleteFile(params: AdminDeleteFileRequestParams): Observable<any> {
    return this._adminService.adminDeleteFile(params);
  }

  // This will recursively call the list files endpoint until we have them all.
  public listAllFiles(accountId: string, purposes: any = null): void {
    this.fileListBuilder = [];
    const requestParams: ListFilesRequestParams = {
      accountId: accountId,
      purposes: purposes,
      offset: 0,
      limit: 100,
    };

    const listFiles$ = this._adminService.listFiles(requestParams);
    listFiles$
      .pipe(
        expand((result) => {
          const hasMore = result.has_more;
          requestParams.offset = result.offset + result.limit;
          if (hasMore) {
            this.fileListBuilder.push(...result.items);
            return this._adminService.listFiles(requestParams);
          }
          this.fileListBuilder.push(...result.items);
          this._filesAll$.next(this.fileListBuilder);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
