/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminExtendedProductCode { 
    account_id: string;
    apple_pay_account_id?: string;
    billing_owner: AdminExtendedProductCode.BillingOwnerEnum;
    card_types?: Array<AdminExtendedProductCode.CardTypesEnum>;
    code: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: AdminExtendedProductCode.CurrencyEnum;
    /**
     * The user friendly description. Example: *2.90% | $0.30 TXN | $10 CHBK/Reversals/Retrievals | $0 Monthly*
     */
    description: string;
    enabled: boolean;
    fee_redirected?: boolean;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The payment method type that this product code is valid for.
     */
    payment_method_type: AdminExtendedProductCode.PaymentMethodTypeEnum;
    payout_owner: AdminExtendedProductCode.PayoutOwnerEnum;
    /**
     * The Region in which the product code is usable
     */
    region?: AdminExtendedProductCode.RegionEnum;
    revenue_account_id?: string;
    txn_fee_amount?: number;
    txn_fee_percent?: number;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace AdminExtendedProductCode {
    export type BillingOwnerEnum = 'provider' | 'tilled';
    export const BillingOwnerEnum = {
        PROVIDER: 'provider' as BillingOwnerEnum,
        TILLED: 'tilled' as BillingOwnerEnum
    };
    export type CardTypesEnum = 'amex' | 'diners' | 'discover' | 'jcb' | 'maestro' | 'mastercard' | 'solo' | 'visa' | 'visa_debit' | 'visa_electron' | 'unknown';
    export const CardTypesEnum = {
        AMEX: 'amex' as CardTypesEnum,
        DINERS: 'diners' as CardTypesEnum,
        DISCOVER: 'discover' as CardTypesEnum,
        JCB: 'jcb' as CardTypesEnum,
        MAESTRO: 'maestro' as CardTypesEnum,
        MASTERCARD: 'mastercard' as CardTypesEnum,
        SOLO: 'solo' as CardTypesEnum,
        VISA: 'visa' as CardTypesEnum,
        VISA_DEBIT: 'visa_debit' as CardTypesEnum,
        VISA_ELECTRON: 'visa_electron' as CardTypesEnum,
        UNKNOWN: 'unknown' as CardTypesEnum
    };
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type PaymentMethodTypeEnum = 'card' | 'ach_debit' | 'card_present' | 'eft_debit';
    export const PaymentMethodTypeEnum = {
        CARD: 'card' as PaymentMethodTypeEnum,
        ACH_DEBIT: 'ach_debit' as PaymentMethodTypeEnum,
        CARD_PRESENT: 'card_present' as PaymentMethodTypeEnum,
        EFT_DEBIT: 'eft_debit' as PaymentMethodTypeEnum
    };
    export type PayoutOwnerEnum = 'provider' | 'tilled';
    export const PayoutOwnerEnum = {
        PROVIDER: 'provider' as PayoutOwnerEnum,
        TILLED: 'tilled' as PayoutOwnerEnum
    };
    export type RegionEnum = 'US' | 'CA';
    export const RegionEnum = {
        US: 'US' as RegionEnum,
        CA: 'CA' as RegionEnum
    };
}


