import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { InitialData } from 'app/app.types';
import { Observable, of } from 'rxjs';
import { FuseNavigationItem } from '../@fuse/components/navigation';
import { UserService } from './core/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<InitialData> {
    return of({
      messages: null,
      navigation: {
        compact: defaultNavigation,
        default: defaultNavigation,
        futuristic: defaultNavigation,
        horizontal: defaultNavigation,
      },
      notifications: null,
      user: this._userService.user,
    });
  }
}

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'accounts',
    title: 'Accounts',
    subtitle: 'Partners & Merchants',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/accounts',
  },
  {
    id: 'users',
    title: 'Users',
    subtitle: 'Console Users',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: '/users',
  },
  {
    id: 'feature-toggles',
    title: 'Feature Toggles',
    subtitle: 'Experimental',
    type: 'basic',
    icon: 'heroicons_outline:beaker',
    link: '/feature-toggles',
  },
  {
    id: 'settings',
    title: 'Settings',
    subtitle: 'Settings',
    type: 'basic',
    icon: 'heroicons_outline:cog',
    link: '/settings',
  },
  {
    id: 'dashboard',
    title: 'Dashboard',
    subtitle: 'At a glance',
    type: 'basic',
    icon: 'heroicons_outline:view-grid',
    link: '/dashboard',
  },
  {
    id: 'api-dashboard',
    title: 'API Dashboard',
    subtitle: 'Metrics',
    type: 'basic',
    icon: 'heroicons_outline:trending-up',
    link: '/api-dashboard',
  },
];
// TODO: If we wanted to offer different navigation, we would need to spend some time building these out.
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
