/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DebitFeeTemplateCreateParams { 
    /**
     * The amount of the fee applied per return, in currency minor units.
     */
    return_fee: number;
    /**
     * The amount of the transaction fee applied to each transaction, in currency minor units or hundredths of a percent.
     */
    transaction_fee: number;
    /**
     * The type of transaction fee this pricing template applies.
     */
    transaction_fee_type: DebitFeeTemplateCreateParams.TransactionFeeTypeEnum;
}
export namespace DebitFeeTemplateCreateParams {
    export type TransactionFeeTypeEnum = 'flat_rate';
    export const TransactionFeeTypeEnum = {
        FLAT_RATE: 'flat_rate' as TransactionFeeTypeEnum
    };
}


