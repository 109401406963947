/**
 * Tilled Admin API
 * Tilled Internal Admin API
 *
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankAccount { 
    /**
     * The name of the person or business that owns the bank account.
     */
    account_holder_name: string;
    /**
     * The ID of the Account associated with the bank account.
     */
    account_id: string;
    /**
     * Name of the bank associated with the routing number (e.g. `WELLS FARGO`).
     */
    bank_name?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The currency the bank account is in. Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
     */
    currency: BankAccount.CurrencyEnum;
    /**
     * Whether this bank account is the default account for its currency.
     */
    default_for_currency: boolean;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The last 4 digits of the bank account number.
     */
    last4: string;
    /**
     * The US bank account routing number.
     */
    routing_number: string;
    /**
     * Bank account status
     */
    status: BankAccount.StatusEnum;
    /**
     * The bank account type
     */
    type: BankAccount.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
}
export namespace BankAccount {
    export type CurrencyEnum = 'aud' | 'cad' | 'dkk' | 'eur' | 'hkd' | 'jpy' | 'nzd' | 'nok' | 'gbp' | 'zar' | 'sek' | 'chf' | 'usd';
    export const CurrencyEnum = {
        AUD: 'aud' as CurrencyEnum,
        CAD: 'cad' as CurrencyEnum,
        DKK: 'dkk' as CurrencyEnum,
        EUR: 'eur' as CurrencyEnum,
        HKD: 'hkd' as CurrencyEnum,
        JPY: 'jpy' as CurrencyEnum,
        NZD: 'nzd' as CurrencyEnum,
        NOK: 'nok' as CurrencyEnum,
        GBP: 'gbp' as CurrencyEnum,
        ZAR: 'zar' as CurrencyEnum,
        SEK: 'sek' as CurrencyEnum,
        CHF: 'chf' as CurrencyEnum,
        USD: 'usd' as CurrencyEnum
    };
    export type StatusEnum = 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';
    export const StatusEnum = {
        NEW: 'new' as StatusEnum,
        VALIDATED: 'validated' as StatusEnum,
        VERIFIED: 'verified' as StatusEnum,
        VERIFICATION_FAILED: 'verification_failed' as StatusEnum,
        ERRORED: 'errored' as StatusEnum
    };
    export type TypeEnum = 'checking' | 'savings';
    export const TypeEnum = {
        CHECKING: 'checking' as TypeEnum,
        SAVINGS: 'savings' as TypeEnum
    };
}


